
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, LinearProgress } from '@mui/material';

function SplashScreen({ imageURL, messageData, bgMobileSplash }) {
   const [progress, setProgress] = useState(0);
   useEffect(() => {
      const timer = setInterval(() => {
         setProgress((oldProgress) => {
            if (oldProgress === 100) {
               clearInterval(timer);
               return 100;
            }
            return Math.min(oldProgress + 5, 100);
         });
      }, 100);
      return () => {
         clearInterval(timer);
      };
   }, []);
   return (
      <Box
         display="flex"
         flexDirection="column"
         alignItems="center"
         justifyContent="center"
         minHeight="100vh"
         bgcolor="white"
         sx={{
            backgroundImage: `url(${bgMobileSplash || ''})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
         }}
      >
         {imageURL ? (
            <Box
               component="img"
               src={imageURL || ''}
               alt="App Icon"
               sx={{
                  width: 100,
                  height: 100,
                  marginBottom: 2,
                  animation: `jump 1s ease-in-out infinite`,
                  "@keyframes jump": {
                     "0%, 100%": {
                        transform: "translateY(0)",
                     },
                     "50%": {
                        transform: "translateY(-10px)",
                     },
                  },
               }}
            />
         ) : (
            <Box
               sx={{
                  width: 100,
                  height: 100,
                  marginBottom: 2,
               }}
            />
         )
         }
         <Typography
            variant="h5"
            width='100%'
            height={25}
            color="textPrimary"
            sx={{ fontWeight: 'bold', marginBottom: 2, textAlign: 'center' }}
         >
            {messageData || ''}
         </Typography>
         <Box width="100px" sx={{ mt: 2 }}>
            <LinearProgress variant="determinate" value={progress} color="primary" />
         </Box>
      </Box>
   );
}
SplashScreen.propTypes = {
   imageURL: PropTypes.string,
   messageData: PropTypes.string,
   bgMobileSplash: PropTypes.string,
};
export default SplashScreen;