import { initializeAppCheck, getToken, ReCaptchaV3Provider } from "firebase/app-check";

const { app } = require('./firesBaseConfig');


// -----------------------------------------------
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
let appCheck;

if (process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'prod') {
  appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_KEY),
  });
}

export const getAppCheckToken = async () => {
  let appCheckTokenResponse;

  try {
    appCheckTokenResponse = await getToken(appCheck, /* forceRefresh= */ false);

  } catch (err) {
    // Handle any errors if the token was not retrieved.
    console.error(err);
  }
  return appCheckTokenResponse;
};