import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from 'react-router-dom';

function ErrorFallback({ resetError }) {
  const navigate = useNavigate();

  const handleReload = () => {
    resetError(); // Reset Error
    navigate('/'); // Navigate to Homepage
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        padding: 3,
        backgroundColor: '#f5f5f5',
      }}
    >
      <ErrorOutlineIcon sx={{ fontSize: 80, color: 'error.main', mb: 2 }} />
      <Typography variant="h3" gutterBottom color="error">
        Oops! Something went wrong.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Please contact support if the problem persists.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        onClick={handleReload}
      >
        Go to Home
      </Button>
      <Button
        variant="outlined"
        size='small'
        sx={{ mt: 3 }}
        href='tel: +91 76669 92059'
      >

        Contact Support
      </Button>
    </Box>
  );
}

ErrorFallback.propTypes = {
  resetError: PropTypes.func.isRequired,
};

export default ErrorFallback;
